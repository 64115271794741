import ApiHandler from "../utils/apiHandler";
import appConfig from "../app.config";

const { PAYMENT_BASE_URL } = appConfig;

export const getCheckoutDetails = (params) => {
  const api = new ApiHandler({
    baseURL: PAYMENT_BASE_URL,
  });

  return api.get({
    url: `/v1/payment/checkout`,
    params,
  });
};

export const getPaymentStatus = (params) => {
  const api = new ApiHandler({
    baseURL: PAYMENT_BASE_URL,
  });

  return api.get({
    url: `/v1/payment/status`,
    params,
  });
};
