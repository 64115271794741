export const SET_NAVIGATE_TO = `SET_NAVIGATE_TO`;
export const SET_RAZORPAY_POP_OPENED = `SET_RAZORPAY_POP_OPENED`;
export const SET_PENDING_CHECK_STARTED = `SET_PENDING_CHECK_STARTED`;
export const SET_PENDING_FOR_TOO_LONG = `SET_PENDING_FOR_TOO_LONG`;

export const GET_CHECKOUT_DATA = `GET_CHECKOUT_DATA`;
export const GET_ORDER_STATUS = `GET_ORDER_STATUS`;

export const SET_INICIS_OPENED = `SET_INICIS_OPENED`;

export const SET_INICIS_MOB_OPENED = `SET_INICIS_MOB_OPENED`;
