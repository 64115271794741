import { call, put, takeLatest, all } from "redux-saga/effects";
import store from ".";
import {
  SET_NAVIGATE_TO,
  SET_PENDING_CHECK_STARTED,
  SET_PENDING_FOR_TOO_LONG,
  SET_RAZORPAY_POP_OPENED,
  SET_INICIS_OPENED,
  SET_INICIS_MOB_OPENED,
} from "./actionTypes";
import { GATEWAY_ENUMS, PAYMENT_STATES } from "../utils/constants";
import handleRazorpayUtil from "../utils/handleRazorpayUtil";
import loadScript from "../utils/loadScript";
import { GET_CHECKOUT_DATA, GET_ORDER_STATUS } from "./actionTypes";
import { getCheckoutDetails, getPaymentStatus } from "./service";
import { buildQuery } from "../utils/buildQuery";
import { redirectWithToken } from "../utils/tokenHelper";

const waitFor = (millis) => new Promise((res) => setTimeout(res, millis));
const redirectTo = (url) => (window.location.href = url);

async function makePayment(data) {
  return new Promise((resolve, reject) =>
    handleRazorpayUtil(data, resolve, reject)
  );
}

function* getData({ payload }) {
  let data;
  try {
    const resp = yield call(getCheckoutDetails, payload);
    data = resp.data;

    if (GATEWAY_ENUMS.RAZORPAY === data.gatewayCode) {
      yield loadScript("https://checkout.razorpay.com/v1/checkout.js");
      yield put({
        type: SET_RAZORPAY_POP_OPENED,
        payload: true,
      });

      const { razorpay_payment_id: gatewayPaymentId } = yield call(
        makePayment,
        data
      );

      yield put({
        type: SET_NAVIGATE_TO,
        payload: "/pending" + buildQuery({ gatewayPaymentId }),
      });
    } else if (GATEWAY_ENUMS?.INICIS === data.gatewayCode) {
      if (data?.paymentRequest?.script) {
        yield loadScript(data?.paymentRequest?.script);
        yield put({
          type: SET_INICIS_OPENED,
          payload: { ...data?.paymentRequest?.payload },
        });
      } else if (data?.paymentRequest?.url) {
        yield put({
          type: SET_INICIS_MOB_OPENED,
          payload: { ...data?.paymentRequest },
        });
      }
      sessionStorage.setItem("closeUrl", data?.paymentRequest?.closeUrl);
      sessionStorage.setItem("closeScript", data?.paymentRequest?.closeScript);
      sessionStorage.setItem("gatewayCode", data?.gatewayCode);
      sessionStorage.setItem("redirectCount", 1);
      // script url -> script load on page , data in payemnt request payload , submit in that script as a form ( WEB )
    } else {
      redirectTo(data.paymentLink);
    }
  } catch (e) {
    try {
      yield put({
        type: SET_NAVIGATE_TO,
        payload: "/failure" + buildQuery({ failureUrl: data.failureUrl }),
      });
    } catch (e) {
      console.log(e);
    }
  }
}

function* getStatus({ payload }) {
  let data;
  try {
    const resp = yield getPaymentStatus(payload);
    data = resp.data;
    const { status } = data;

    switch (status) {
      case PAYMENT_STATES.success:
        yield put({
          type: SET_NAVIGATE_TO,
          payload: "/success" + buildQuery({ successUrl: data.redirectUrl }),
        });
        break;
      case PAYMENT_STATES.failure:
        yield put({
          type: SET_NAVIGATE_TO,
          payload: "/failure" + buildQuery({ failureUrl: data.redirectUrl }),
        });
        break;
      case PAYMENT_STATES.pending:
        let { pendingCheckStartedAt } = store.getState();
        if (!pendingCheckStartedAt) {
          pendingCheckStartedAt = Date.now();
          yield put({
            type: SET_PENDING_CHECK_STARTED,
            payload: pendingCheckStartedAt,
          });
        }

        if (Date.now() - pendingCheckStartedAt < 2 * 60 * 1000) {
          yield waitFor(3000);
          yield put({
            type: GET_ORDER_STATUS,
            payload,
          });
        } else {
          yield put({
            type: SET_PENDING_FOR_TOO_LONG,
            payload: true,
          });
          yield waitFor(3000);
          redirectWithToken(data.redirectUrl);
        }
    }
  } catch (e) {
    console.log(e);
  }
}

function* CheckoutSagaWatcher() {
  yield takeLatest(GET_CHECKOUT_DATA, getData);
  yield takeLatest(GET_ORDER_STATUS, getStatus);
}

export default function* rootSagas() {
  yield all([CheckoutSagaWatcher()]);
}
