import axios from "axios";
import { getQueryParams } from "./getQueryParams";
import { COMMON_DEEPLINK_ACTIONS } from "./constants";
import { hitDeeplinkExecutedV2 } from "./hitDeeplink";
import parseError from "./parseError";

class ApiHandler {
  constructor({ baseURL, headers } = {}) {
    this.client = axios.create({ baseURL, headers });
    this.client.interceptors.request.use(
      (config) => {
        const { token, region } = getQueryParams();
        if (token) {
          config.headers.common["authorization"] = token;
        }
        if (region) {
          config.headers.common["region"] = region;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.client.interceptors.response.use(
      // RETURNING RESPONSE ON SUCCESS
      ({ data }) => data,
      // HANDLING ERROR
      (err) => {
        parseError(err);

        // RUNNING THE REFRESH TOKEN LOGIC IF ERROR RESPONSE CODE IS 401
        if (err?.response?.status === 401) {
          hitDeeplinkExecutedV2(
            JSON.stringify({
              screen: COMMON_DEEPLINK_ACTIONS.UTIL_LOG_OUT,
            }),
            JSON.stringify({
              screen: COMMON_DEEPLINK_ACTIONS.UTIL_LOG_OUT,
            })
          );
          return;
        }
        // RETURNING THE AXIOS ERROR
        return Promise.reject(err);
      }
    );
  }

  /**
   * @param {import("axios").AxiosRequestConfig["method"]} reqType
   * @param {import("axios").AxiosRequestConfig} config
   */
  makeRequest(reqType, config) {
    return this.client.request({
      ...config,
      method: reqType,
    });
  }

  /** @param {import("axios").AxiosRequestConfig} config */
  get(config) {
    return this.makeRequest("get", config);
  }

  /** @param {import("axios").AxiosRequestConfig} config */
  patch(config) {
    return this.makeRequest("patch", config);
  }

  /** @param {import("axios").AxiosRequestConfig} config */
  put(config) {
    return this.makeRequest("put", config);
  }

  /** @param {import("axios").AxiosRequestConfig} config */
  post(config) {
    return this.makeRequest("post", config);
  }

  /** @param {import("axios").AxiosRequestConfig} config */
  delete(config) {
    return this.makeRequest("delete", config);
  }
}

export default ApiHandler;
