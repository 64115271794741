import { configureStore } from "@reduxjs/toolkit";
// import { combineReducers } from 'redux';
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducer";
import rootSagas from "./sagas";

const sagaMiddleware = createSagaMiddleware();
export default configureStore({
  reducer: rootReducer,
  middleware: (defaultMiddleware) => defaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSagas);
