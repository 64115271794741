import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useNavigator = () => {
  const navigateTo = useSelector(({ navigateTo }) => navigateTo);

  const navigate = useNavigate();
  const isFirstMount = useRef(true);

  useEffect(() => (isFirstMount.current = false), []);

  useEffect(() => {
    if (!isFirstMount.current && navigateTo) {
      navigate(navigateTo, { replace: true });
    }
  }, [navigateTo]);
};

export default useNavigator;
