export const COMMON_DEEPLINK_ACTIONS = {
  UTIL_COPY: "UTIL_COPY",
  UTIL_SHARE: "UTIL_SHARE",
  SOCIAL_LINKS_SHARE: "SOCIAL_LINKS_SHARE",
  UTIL_LOG_OUT: "UTIL_LOG_OUT",
};

export const GATEWAY_ENUMS = {
  RAZORPAY: "rzp",
  TAZAPAY: "tzp",
  RAPYD: "rpd",
  INICIS: "ini",
};

export const PAYMENT_STATES = {
  success: "success",
  failure: "failed",
  pending: "pending",
};
