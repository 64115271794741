import { GET_CHECKOUT_DATA, GET_ORDER_STATUS } from "./actionTypes";

export const getCheckoutData = (payload) => {
  return {
    type: GET_CHECKOUT_DATA,
    payload,
  };
};

export const getOrderStatus = (payload) => {
  return {
    type: GET_ORDER_STATUS,
    payload,
  };
};
