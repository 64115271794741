export const buildQuery = (queryObj = {}, appendInCurrentQuery = true) => {
  const searchParams = new URLSearchParams(
    appendInCurrentQuery ? window?.location?.search : undefined
  );
  Object.entries(queryObj).forEach(([key, value]) =>
    searchParams.set(key, value)
  );

  const stringifiedQuery = searchParams.toString();
  return stringifiedQuery ? "?" + stringifiedQuery : "";
};
