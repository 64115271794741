export const getQueryParams = ({ search, url } = {}) => {
  if (url) {
    search = new URL(url).search;
  }
  const parsedQueryParams = {};
  const searchParams = new URLSearchParams(search || window?.location?.search);
  for (let [key, value] of searchParams) {
    parsedQueryParams[key] = value;
  }

  return parsedQueryParams;
};
