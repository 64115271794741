import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PromptPlaceholder from "../../components/promptPlaceholder";
import Thumb from "../../images/Failure.svg";
import { getQueryParams } from "../../utils/getQueryParams";
import { redirectWithToken } from "../../utils/tokenHelper";
import useLanguageSelector from "../../_hooks/useLanguageSelector";
import useNavigator from "../../_hooks/useNavigator";

const Failure = () => {
  // added encodedFailureUrl to handle Rapyd's case where urls can't be passed in query params
  const {
    defaultLanguage,
    failureUrl,
    encodedFailureUrl,
    region,
    gatewayCode,
  } = useMemo(getQueryParams, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      // TODO: implement alternate for base64 decrypting as atob is deprecated
      const url = failureUrl || atob(encodedFailureUrl);
      redirectWithToken(url);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);
  useLanguageSelector(defaultLanguage);
  useNavigator();

  useLayoutEffect(() => {
    if (
      region === "KR" &&
      sessionStorage.getItem("gatewayCode") === "ini" &&
      sessionStorage.getItem("redirectCount") == 1 &&
      window?.innerWidth > 600
    ) {
      let scriptFromBe = sessionStorage.getItem("closeScript");
      console.log(sessionStorage.getItem("redirectCount"), "seses");
      const script = document.createElement("script");
      script.src = scriptFromBe;
      script.async = true;
      document.body.appendChild(script);
      if (window) {
        console.log("windowwindow");
        sessionStorage.setItem("redirectCount", 0);
        window.parent.location.href = sessionStorage.getItem("closeUrl");
      }
    }
  }, [region, window]);

  const { t } = useTranslation();
  return (
    <PromptPlaceholder
      heading={t(
        "src.pages.home.index.paymentFailedScreenHeading",
        "Payment failed!"
      )}
      icon={Thumb}
      className="Failure"
    >
      {t(
        "src.pages.home.index.paymentFailedScreenText",
        `Your payment could not be completed. Please try again. 
        Don't worry! In case money has been debited from your account, it will be refunded back to your payment source within 10-12 working days.`
      )}
    </PromptPlaceholder>
  );
};
export default Failure;
