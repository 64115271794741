import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PromptPlaceholder from "../../components/promptPlaceholder";
import Thumb from "../../images/Pending.svg";
import { getQueryParams } from "../../utils/getQueryParams";
import useNavigator from "../../_hooks/useNavigator";
import { getOrderStatus } from "../../store/actions";
import { useTranslation } from "react-i18next";
import useLanguageSelector from "../../_hooks/useLanguageSelector";

const Pending = () => {
  const { defaultLanguage, gatewayPaymentId, clientCode } = useMemo(
    getQueryParams,
    []
  );
  const { pendingForTooLong } = useSelector((s) => s);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrderStatus({ clientCode, gatewayPaymentId }));
  }, []);

  useLanguageSelector(defaultLanguage);
  useNavigator();

  // I18NEXT HOOK
  const { t } = useTranslation();

  return pendingForTooLong ? (
    <PromptPlaceholder
      heading={t(
        "src.pages.pending.index.paymentProcessingScreenHeading1",
        "Taking longer than usual"
      )}
      icon={Thumb}
      className="Pending"
    >
      {t(
        "src.pages.pending.index.paymentProcessingScreenText1",
        "Please wait while we redirect you back to the merchant's portal."
      )}
    </PromptPlaceholder>
  ) : (
    <PromptPlaceholder
      heading={t(
        "src.pages.pending.index.paymentProcessingScreenHeading2",
        "Payment Processing"
      )}
      icon={Thumb}
      className="Pending"
    >
      {t(
        "src.pages.pending.index.paymentProcessingScreenText2",
        "Please do not close this application or use the back button. It may take a few seconds."
      )}
    </PromptPlaceholder>
  );
};

export default Pending;
