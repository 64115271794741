import { buildQuery } from "./buildQuery";
import { getQueryParams } from "./getQueryParams";

const TOKEN_KEY = "returnUrlToken";

export const redirectWithToken = (url) => {
  const urlObj = new URL(url);
  let token = getQueryParams()[TOKEN_KEY];
  if (!token) {
    token = localStorage.getItem(TOKEN_KEY);
  }

  const search = !token
    ? urlObj.search
    : buildQuery({
        ...getQueryParams({ search: urlObj.search }),
        token,
      });

  localStorage.removeItem(TOKEN_KEY);
  const redirectUrl = urlObj.origin + urlObj.pathname + search;
  window.location.href = redirectUrl;
};

export const saveToken = () => {
  const token = getQueryParams()[TOKEN_KEY];
  if (token) {
    localStorage.setItem(TOKEN_KEY, token);
  }
};
