// UTIL TO HIT DEEPLINK EXECUTED V2
// deeplinkObj SHOULD BE STRINGIFIED AS ARGUMENTS
// android iOS BOOLEAN FLAGS DENOTING IF DEEPLINK TO BE EXECUTED FOR THE RESPECTIVE PLATFORM (true by default)
export const hitDeeplinkExecutedV2 = (
  androidDeeplinkObj,
  iosDeeplinkObj,
  android = true,
  iOS = true
) => {
  try {
    android && window?.mobile?.onDeeplinkExecutedV2(androidDeeplinkObj);
    iOS &&
      window?.webkit?.messageHandlers?.onDeeplinkExecutedV2?.postMessage(
        iosDeeplinkObj
      );
  } catch (err) {
    alert("Something went wrong...");
  }
};
