const handleRazorpayUtil = (config, onSuccess, onError) => {
  let {
    gatewayConfig,
    gatewayNotes: notes,
    prefill,
    gatewayKey: key,
    orgImageUrl,
    gatewayOrderId,
    orgName,
  } = config;

  const options = {
    key,
    image: orgImageUrl,
    order_id: gatewayOrderId,
    config: gatewayConfig,
    prefill,
    notes,
    ...(orgName && { name: orgName }),
    handler: onSuccess,
    readonly: {
      email: 1,
      contact: 1,
    },
    modal: {
      ondismiss: onError,
    },
    retry: {
      enabled: false,
    },
  };

  const instance = new window.Razorpay(options);
  instance.on("payment.failed", onError);
  instance.open();
};

export default handleRazorpayUtil;
