import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";

// ROUTE PAGES
import Home from "./pages/home";
import Success from "./pages/success";
import Failure from "./pages/failure";
import Pending from "./pages/pending";

const App = () => {
  return (
    <div className="appWrapper">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/success" element={<Success />} />
          <Route path="/pending" element={<Pending />} />
          <Route path="/failure" element={<Failure />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
