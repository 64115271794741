import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import PromptPlaceholder from "../../components/promptPlaceholder";
import Thumb from "../../images/Success.svg";
import { getQueryParams } from "../../utils/getQueryParams";
import { redirectWithToken } from "../../utils/tokenHelper";
import useLanguageSelector from "../../_hooks/useLanguageSelector";
import useNavigator from "../../_hooks/useNavigator";
const Success = () => {
  const { defaultLanguage, successUrl } = useMemo(getQueryParams, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      redirectWithToken(successUrl);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);
  useLanguageSelector(defaultLanguage);
  useNavigator();
  const { t } = useTranslation();
  return (
    <PromptPlaceholder
      className="Success"
      heading={t(
        "src.pages.home.index.paymentSuccessScreenHeading",
        "Payment Successful"
      )}
      icon={Thumb}
    >
      {t(
        "src.pages.home.index.paymentSuccessScreenText",
        "Congratulations! Your payment is successful. You will be soon redirected to your merchant’s page."
      )}
    </PromptPlaceholder>
  );
};
export default Success;
