import {
  SET_NAVIGATE_TO,
  SET_PENDING_CHECK_STARTED,
  SET_PENDING_FOR_TOO_LONG,
  SET_RAZORPAY_POP_OPENED,
  SET_INICIS_OPENED,
  SET_INICIS_MOB_OPENED,
} from "./actionTypes";

const initialState = {
  navigateTo: null,
  razorPayPopupOpened: false,
  pendingCheckStartedAt: null,
  pendingForTooLong: false,
  inicisPayload: {},
  inicisMobPayload: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RAZORPAY_POP_OPENED:
      return { ...state, razorPayPopupOpened: action.payload };
    case SET_NAVIGATE_TO:
      return { ...state, navigateTo: action.payload };
    case SET_PENDING_CHECK_STARTED:
      return { ...state, pendingCheckStartedAt: action.payload };
    case SET_PENDING_FOR_TOO_LONG:
      return { ...state, pendingForTooLong: action.payload };

    case SET_INICIS_OPENED:
      return { ...state, inicisPayload: { ...action?.payload } };
    case SET_INICIS_MOB_OPENED:
      return { ...state, inicisMobPayload: { ...action?.payload } };
    default:
      return state;
  }
};

export default reducer;
