import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import PromptPlaceholder from "../../components/promptPlaceholder";
import { getQueryParams } from "../../utils/getQueryParams";
import { saveToken } from "../../utils/tokenHelper";
import useNavigator from "../../_hooks/useNavigator";
import Thumb from "../../images/Loading.svg";
import { getCheckoutData } from "../../store/actions";
import useLanguageSelector from "../../_hooks/useLanguageSelector";

const Home = () => {
  const dispatch = useDispatch();
  const { razorPayPopupOpened, inicisPayload, inicisMobPayload } = useSelector(
    (s) => s
  );

  const { clientCode, orgId, defaultLanguage } = useMemo(getQueryParams, []);
  useEffect(() => {
    dispatch(getCheckoutData({ clientCode, orgId }));
    saveToken();
  }, []);

  useLanguageSelector(defaultLanguage);
  useNavigator();

  // I18NEXT HOOK
  const { t } = useTranslation();

  // const newForm = document.createElement("form");
  // newForm.setAttribute("id", "myForm");

  // var temp = document.querySelector("#myForm");

  useLayoutEffect(() => {
    if (window?.INIStdPay && inicisPayload) {
      let newForm = document.querySelector("#myForm");
      for (const fieldName of Object.keys(inicisPayload)) {
        const input = document.createElement("input");
        input.type = "text";
        input.name = fieldName;
        input.value = inicisPayload[fieldName];
        newForm.appendChild(input);
      }

      let temp = document.getElementById("myForm");
      temp.style.display = "none";
      window?.INIStdPay.pay("myForm");
    } else if (inicisMobPayload?.url) {
      let newForm = document.querySelector("#myForm");
      let inicisPayload = inicisMobPayload?.payload;
      for (const fieldName of Object.keys(inicisPayload)) {
        const input = document.createElement("input");
        input.type = "text";
        input.name = fieldName;
        input.value = inicisPayload[fieldName];
        newForm.appendChild(input);
      }

      let temp = document.getElementById("myForm");
      temp.style.display = "none";
      temp.action = inicisMobPayload?.url;
      temp.target = "_self";
      temp.submit();
    }
  }, [inicisPayload, inicisMobPayload]);

  return (
    <>
      <div style={{ display: "hidden !important" }}>
        <form name="" id="myForm" method="post" acceptCharset="EUC-KR"></form>
      </div>

      {!razorPayPopupOpened && (
        <>
          <PromptPlaceholder
            heading={t(
              "src.pages.home.index.paymentInitiatingScreenHeading",
              "Initiating payment"
            )}
            icon={Thumb}
            className="Loading"
          >
            {t(
              "src.pages.home.index.paymentInitiatingScreenText",
              "We are processing your payment request. Please be patient and do not close this window before completion"
            )}
          </PromptPlaceholder>
        </>
      )}
    </>
  );
};

export default Home;
