const loadScript = (() => {
  const addedScripts = {};

  return (url) => {
    addedScripts[url] =
      addedScripts[url] ||
      new Promise((res) => {
        const script = document.createElement("script");

        script.onload = res;
        script.src = url;
        script.async = true;

        document.body.appendChild(script);
      });

    return addedScripts[url];
  };
})();

export default loadScript;
