const staging = {
  PAYMENT_BASE_URL: "https://apip-staging.classplusapp.com",
};

const preprod = {
  PAYMENT_BASE_URL: "https://apip-preprod.classplusapp.com",
};

const qa = {
  PAYMENT_BASE_URL: "https://apip-qa.classplusapp.com",
};


const production = {
  PAYMENT_BASE_URL: "https://api.classplusapp.com",
};

const config = { qa, staging, production, preprod };
const envConfig = config[process.env.REACT_APP_ENVIRONMENT];

export default envConfig;
