import React from "react";
import CTA from "../cta";
import "./styles.scss";

const PromptPlaceholder = ({
  icon,
  heading,
  children,
  button,
  onClick,
  className,
}) => {
  const renderIcon = () =>
    typeof icon === "string" ? (
      <img className="PromtPlaceholder__Thumb" src={icon} alt="paymentIcon" />
    ) : (
      icon
    );
  return (
    <div
      className={
        className ? `PromtPlaceholder ${className}` : "PromtPlaceholder"
      }
    >
      {!!icon && renderIcon()}
      <div className="PromtPlaceholder__Heading">{heading}</div>
      <div className="PromtPlaceholder__Body">{children}</div>

      {!!button && (
        <div className="PromtPlaceholder__Action">
          <CTA label={button} onClick={onClick} />
        </div>
      )}
    </div>
  );
};

export default PromptPlaceholder;
